<template>
  <div
    v-if="libraryType"
    class="library"
  >
    <section
      v-if="libraryType"
      class="main-content"
    >
      <LibraryContentHeader
        :sorting="sorting"
        :new-item-files="newItemFiles"
        @sorting-change="onSortingChange"
        @search-update="fetchData(items, fetchFilteredItems)"
      />

      <LibraryItems
        v-if="items"
        :items="items"
        :library-type="libraryType"
        :user="user"
        :loading="isLoading"
        @scroll-hit-bottom="onScrollHitBottom"
        @files-dropped="onFilesDropped"
      />
    </section>

    <template v-if="detailId">
      <DetailStyle
        v-if="libraryType === LIBRARY_TYPE.STYLE"
        :item-id="detailId"
        @close="closeDetail"
      />
      <DetailBlock
        v-else-if="libraryType === LIBRARY_TYPE.BLOCK"
        :item-id="detailId"
        @close="closeDetail"
      />
      <DetailItem
        v-else
        :item-id="detailId"
      />
    </template>

    <div class="library__aside-right-container">
      <TheAsideRight
        class="library__aside-right"
        @filter-library="fetchData(items, fetchFilteredItems)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { FetchData } from '@/mixins/fetchData.js'
import Utils from '@/services/utils'
import { getFormConfig } from '@/services/formUtils'
import DetailItem from '@/components/library/DetailItem'
import LibraryContentHeader from '@/components/library/LibraryContentHeader'

export default {
  name: 'ItemsLibrary',

  components: {
    DetailItem,
    LibraryContentHeader
  },

  mixins: [FetchData],

  data () {
    return {
      LIBRARY_TYPE,
      detailId: null,
      newItemFiles: null,
      isLoading: false
    }
  },

  computed: {
    ...mapGetters([
      'getItems',
      'getSortingItems',
      'getAvailableFilters',
      'getItemDetail'
    ]),

    ...mapGetters({
      browzwearAPI: 'getBrowzwearAPI',
      user: 'getCognitoUserData',
      libraryType: 'getActiveLibraryType'
    }),

    /**
     * @returns {Array}
     */
    items () {
      return this.getItems()
    },

    /**
     * @returns {object}
     */
    itemDetail () {
      return this.getItemDetail()
    },

    /**
     * @returns {Array}
     */
    sorting () {
      return this.getSortingItems()
    },

    /**
     * @returns {Array}
     */
    availableFilters () {
      return this.getAvailableFilters()
    },

    /**
     * @returns {Array}
     */
    camelCasedFilters () {
      return Utils.convertObjectKeysToCamelCase(this.availableFilters)
    },

    /**
     * @returns {object}
     */
    formConfiguration () {
      return getFormConfig(this.libraryType)
    }
  },

  watch: {
    /**
     * @param {string} to
     * @param {string} from
     */
    $route (to, from) {
      if (from.name !== to.name) {
        this.updateWorkspace()
      }

      this.parseUrlForDetail()
    }
  },

  mounted () {
    this.updateWorkspace()
    this.parseUrlForDetail()
  },

  methods: {
    ...mapActions([
      'setActiveLibraryType',
      'setSortingItems',
      'incrementPagingItems',
      'fetchFilteredItems',
      'fetchAvailableFilters',
      'fetchTags',
      'resetItems'
    ]),

    /**
     */
    async updateWorkspace () {
      this.isLoading = true

      this.setActiveLibraryType(this.$router.currentRoute.meta.libraryType)
      this.resetItems()

      await this.fetchFilters()

      await this.fetchData(this.items, this.fetchFilteredItems)

      this.isLoading = false

      if (this.libraryType === LIBRARY_TYPE.STYLE) {
        this.fetchTags({
          assignedOnly: true
        })
      }
    },

    /**
     */
    async fetchFilters () {
      if (!this.availableFilters) {
        await this.fetchAvailableFilters()
      }
    },

    /**
     */
    closeDetail () {
      if (!this.detailId) {
        // avoid duplicated navigation
        return
      }

      this.detailId = null
      const newQueryUrl = JSON.parse(JSON.stringify(this.$route.query))
      delete newQueryUrl.detail

      this.$router.replace({
        query: newQueryUrl
      })
    },

    /**
     */
    parseUrlForDetail () {
      this.detailId = this.$route.query.detail
        ? parseInt(this.$route.query.detail)
        : null
    },

    /**
     * @param {string} changedSorting
     */
    onSortingChange (changedSorting) {
      if (this.sorting === changedSorting) {
        return
      }

      this.setSortingItems({ sorting: changedSorting })
      this.fetchData(this.items, this.fetchFilteredItems, true)
    },

    /**
     */
    onScrollHitBottom () {
      this.incrementPagingItems()
      this.fetchData(this.items, this.fetchFilteredItems, true)
    },

    /**
     * @param {object[]} files
     */
    onFilesDropped (files) {
      this.newItemFiles = files
    }
  }
}
</script>

<style lang="scss" scoped>
$aside-right-min-width: spacing(27);
$aside-right-height: 90vh;
$main-width-small: calc(100% - #{$aside-right-min-width});
$main-width: calc(100% - #{$aside-width});

.library {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.main-content {
  flex: 0 1 auto;
  align-self: flex-start;
  width: 100%;
  height: 100%;
  margin-top: spacing(2);

  @media (max-width: $media-query-xs) {
    width: 100%;
  }
}

.library__aside-right-container {
  flex: 1 0 auto;
  width: $aside-width;
  min-width: $aside-right-min-width;
  height: 100%;
  padding: spacing(2) spacing(2) spacing(4) spacing(4);

  @media (max-width: $media-query-s) {
    display: none;
  }
}

.library__aside-right {
  align-self: flex-start;
  height: 100%;
  border: $border-divider;
  border-radius: spacing(1/2);
  box-shadow: $shadow-default;
}

.library__detail-item-dropdown {
  margin-left: spacing(2);
}

.library__detail-item-dropdown--danger {
  color: $red;

  &:hover {
    color: $red;
  }
}
</style>
